/*=========================================================================================
  File Name: moduleOfferActions.js
  Description: Offer Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";

export default {
	setOfferSearchQuery ({ commit }, query) {
		commit("SET_OFFER_SEARCH_QUERY", query);
	},
	fetchOffers ({ commit }) {
		const org_id = localStorage.getItem("org_id");
		return new Promise((resolve, reject) => {
			axios.get("/offers/all?org_id="+org_id)
				.then((response) => {
					commit("SET_OFFERS", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addOffer ({ commit }, offer) {
		return new Promise((resolve, reject) => {
			axios.post("/offers", offer)
				.then((response) => {
					commit("ADD_OFFER", Object.assign(offer, response.data));
					router.push("/offers/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateOffer ({ commit }, offer) {
		return new Promise((resolve, reject) => {
			axios.put("/offers", offer)
				.then((response) => {
					commit("UPDATE_OFFER", Object.assign(offer, response.data));
					router.push("/offers/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteOffer ({ commit }, offer_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/offers?offerId=" + offer_id)
				.then((response) => {
					commit("DELETE_OFFER", offer_id);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	uploadImage ({ commit }, obj) {
		return new Promise((resolve) => {  
			var data = new FormData();
			data.append("bucketName", "offers");
			data.append("file_name", "image");
			data.append("image", obj.image);
      
			var xhr = new XMLHttpRequest();
      
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState === 4 && this.status === 200) {
					const jsonResponse = JSON.parse(this.responseText);
					if (!obj.employee_id) {
					} else {
						commit("UPLOAD_OFFER_IMAGE", {image_url:jsonResponse.url, offer_id:obj.offer_id});
					}
					resolve(jsonResponse.url);
				}
			});
			xhr.open("POST", axios.defaults.baseURL + "/fileupload");
			xhr.send(data);
		});
	},
	removeImage({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "offers");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var removeImage = new FormData();
			removeImage.append("image", obj.oldImage);
			var object = {};
			removeImage.forEach((value, key) => object[key] = value);
			var json = JSON.stringify(object);
			var xhr = new XMLHttpRequest();
			axios.post("/fileremove", json);
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.media_id){
					}else{
						commit("REPLACE_UPLOAD_OFFER_IMAGE", {thumb_url:jsonResponse.url, media_id:obj.media_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},
};
