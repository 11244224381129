/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {

	initializeDashboard ({ commit , state }, {org_id, date_from, date_to, date_window}) {
		return new Promise((resolve, reject) => {
			axios.get(`/organization-dash?org_id=${org_id}&date_from=${date_from}&date_to=${date_to}&date_window=${date_window}`)
				.then((response) => {
					commit("SET_DASHBOARD", response.data);
					resolve();
				})
				.catch((error) => { reject(error); });
		});
	},

	initializeDashboard2 ({ commit , state }, {org_id, date_from, date_to, date_window}) {
		return new Promise((resolve, reject) => {
			axios.get(`/organization-analytical-dash?org_id=${org_id}&date_from=${date_from}&date_to=${date_to}&date_window=${date_window}`)
				.then((response) => {
					commit("SET_DASHBOARD2", response.data);
					resolve(response.data);
				})
				.catch((error) => { reject(error); });
		});
	}

};
