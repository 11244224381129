/*=========================================================================================
  File Name: moduleChatroomActions.js
  Description: Chatroom Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";

export default {
	setChatroomSearchQuery ({ commit }, query) {
		commit("SET_CHATROOM_SEARCH_QUERY", query);
	},
	fetchChatrooms ({ commit }) {
		const org_id = localStorage.getItem("org_id");
		return new Promise((resolve, reject) => {
			axios.get(`/chatrooms/all?org_id=${org_id}&org_panel=1`)
				.then((response) => {
					commit("SET_CHATROOMS", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addChatroom ({ commit }, chatroom) {
		return new Promise((resolve, reject) => {
			axios.post("/chatrooms", chatroom)
				.then((response) => {
					//Quickblox code started here
					if(response.status == 200)
					{     
						var APPLICATION_ID = axios.defaults.QB_APPLICATION_ID;
						var AUTH_KEY = axios.defaults.QB_AUTH_KEY;
						var AUTH_SECRET = axios.defaults.QB_AUTH_SECRET;
						var ACCOUNT_KEY = axios.defaults.QB_ACCOUNT_KEY;
						var CONFIG = axios.defaults.QB_CONFIG;
						var qb_login = axios.defaults.QB_LOGIN;
						var qb_pwd = axios.defaults.QB_PWD;
						var userData = JSON.parse(localStorage.getItem("userInfo"));
						var qb_user_id = userData.qb_user_id;
						var org_emp_id = userData.employee_id;
						var length = 10;
						var result           = [];
						var characters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
						var charactersLength = characters.length;
						for ( var i = 0; i < length; i++ ) {
							result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
						}
						var qb_login_name =result.join("");// userData.first_name.replace(/[^A-Z0-9]+/ig, "");


						QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);

						var params = { login: qb_login, password: qb_pwd };

						QB.createSession(params, function(error, result) {
							// callback function              
							if(qb_user_id == 0){
								//If Organization is creating the chatroom first time we need to create them as admin user in Qulickblox
								var create_user_params = {
									login: qb_login_name,
									password: axios.defaults.QB_CHAT_TMP_PWD,
									full_name: qb_login_name
								};
                
								QB.users.create(create_user_params, function(create_user_error, create_user_result) {
									if (create_user_error) {
										//console.log("Create user error: " + JSON.stringify(create_user_error));
										if(create_user_error.code == 422){
											var RandNo = Math.floor(Math.random() * 9999) + 1;
											var create_user_params = {
												login: qb_login_name+RandNo,
												password: axios.defaults.QB_CHAT_TMP_PWD,
												full_name: qb_login_name
											};
                      
											QB.users.create(create_user_params, function(create_user_error1, create_user_result1) {
												qb_user_id = create_user_result1.id;                                                
												if(qb_user_id != 0)
												{
													userData.qb_user_id = qb_user_id;
													localStorage.setItem("userInfo", JSON.stringify(userData));
													//Update QB data user data to DB
													var qbuserdata = {};
													qbuserdata["update_type"] = 1;
													qbuserdata["update_prime_id"] = org_emp_id;
													qbuserdata["qb_user_id"] = qb_user_id;
													qbuserdata["qb_login"] = qb_login_name+RandNo;
													qbuserdata["qb_pwd"] = axios.defaults.QB_CHAT_TMP_PWD;
													//console.log(qbuserdata);
													axios.post("/chatrooms/quickblox", qbuserdata).then((qbuserdata_response) => {
														//console.log(qbuserdata_response);
													});
													//Update QB data user data to DB end

													var userCredentials = {
														userId: result.user_id,
														password: qb_pwd
													};
                            
													QB.chat.connect(userCredentials, function(error, contactList) {
                            
														var params = {
															type: 2,
															occupants_ids: [qb_user_id],
															name: response.data.name
														};
            
														QB.chat.dialog.create(params, function(error, conversation) {
															//Update QB group data to DB
															var qbgroupdata = {};
															qbgroupdata["update_type"] = 2;
															qbgroupdata["update_prime_id"] = response.data.room_id;
															qbgroupdata["qb_group_id"] = conversation._id;                                
															//console.log(qbgroupdata);
															axios.post("/chatrooms/quickblox", qbgroupdata).then((qbgroupdata_response) => {
																//console.log(qbgroupdata_response);
															});
															//Update QB group data to DB end
															QB.chat.disconnect();
															QB.destroySession(function(error) {});
														});
            
													});
												}                        
											});
										}else{
											qb_user_id = create_user_result.id;                               
											if(qb_user_id != 0)
											{
												userData.qb_user_id = qb_user_id;
												localStorage.setItem("userInfo", JSON.stringify(userData));
												//Update QB data user data to DB
												var qbuserdata = {};
												qbuserdata["update_type"] = 1;
												qbuserdata["update_prime_id"] = org_emp_id;
												qbuserdata["qb_user_id"] = qb_user_id;
												qbuserdata["qb_login"] = qb_login_name;
												qbuserdata["qb_pwd"] = axios.defaults.QB_CHAT_TMP_PWD;
												//console.log(qbuserdata);
												axios.post("/chatrooms/quickblox", qbuserdata).then((qbuserdata_response) => {
													//console.log(qbuserdata_response);
												});
												//Update QB data user data to DB end

												var userCredentials = {
													userId: result.user_id,
													password: qb_pwd
												};
                            
												QB.chat.connect(userCredentials, function(error, contactList) {
                            
													var params = {
														type: 2,
														occupants_ids: [qb_user_id],
														name: response.data.name
													};
            
													QB.chat.dialog.create(params, function(error, conversation) {
														//Update QB group data to DB
														var qbgroupdata = {};
														qbgroupdata["update_type"] = 2;
														qbgroupdata["update_prime_id"] = response.data.room_id;
														qbgroupdata["qb_group_id"] = conversation._id;                                
														//console.log(qbgroupdata);
														axios.post("/chatrooms/quickblox", qbgroupdata).then((qbgroupdata_response) => {
															//console.log(qbgroupdata_response);
														});
														//Update QB group data to DB end
														QB.chat.disconnect();
														QB.destroySession(function(error) {});
													});
            
												});
											}
										}                          
									} else {
										qb_user_id = create_user_result.id;
										if(qb_user_id != 0)
										{
											userData.qb_user_id = qb_user_id;
											localStorage.setItem("userInfo", JSON.stringify(userData));
											//Update QB data user data to DB
											var qbuserdata = {};
											qbuserdata["update_type"] = 1;
											qbuserdata["update_prime_id"] = org_emp_id;
											qbuserdata["qb_user_id"] = qb_user_id;
											qbuserdata["qb_login"] = qb_login_name;
											qbuserdata["qb_pwd"] = axios.defaults.QB_CHAT_TMP_PWD;
											//console.log(qbuserdata);
											axios.post("/chatrooms/quickblox", qbuserdata).then((qbuserdata_response) => {
												//console.log(qbuserdata_response);
											});
											//Update QB data user data to DB end
											var userCredentials = {
												userId: result.user_id,
												password: qb_pwd
											};
                        
											QB.chat.connect(userCredentials, function(error, contactList) {
                        
												var params = {
													type: 2,
													occupants_ids: [qb_user_id],
													name: response.data.name
												};
        
												QB.chat.dialog.create(params, function(error, conversation) {
													//Update QB group data to DB
													var qbgroupdata = {};
													qbgroupdata["update_type"] = 2;
													qbgroupdata["update_prime_id"] = response.data.room_id;
													qbgroupdata["qb_group_id"] = conversation._id;                                
													//console.log(qbgroupdata);
													axios.post("/chatrooms/quickblox", qbgroupdata).then((qbgroupdata_response) => {
														//console.log(qbgroupdata_response);
													});
													//Update QB group data to DB end
													QB.chat.disconnect();
													QB.destroySession(function(error) {});
												});
        
											});
										}
									}
								});
							}else{
								var userCredentials = {
									userId: result.user_id,
									password: qb_pwd
								};
                  
								QB.chat.connect(userCredentials, function(error, contactList) {                  
									var params = {
										type: 2,
										occupants_ids: [qb_user_id],
										name: response.data.name
									};
  
									QB.chat.dialog.create(params, function(error, conversation) {
										//Update QB group data to DB
										var qbgroupdata = {};
										qbgroupdata["update_type"] = 2;
										qbgroupdata["update_prime_id"] = response.data.room_id;
										qbgroupdata["qb_group_id"] = conversation._id;                                
										//console.log(qbgroupdata);
										axios.post("/chatrooms/quickblox", qbgroupdata).then((qbgroupdata_response) => {
											//console.log(qbgroupdata_response);
										});
										//Update QB group data to DB end
										QB.chat.disconnect();
										QB.destroySession(function(error) {});
									});
  
								});
							}             
						}); 
					}
					//Quickblox code ended here                  
					commit("ADD_CHATROOM", Object.assign(chatroom, response.data));
					router.push("/chatroom/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateChatroom ({ commit }, chatroom) {
		return new Promise((resolve, reject) => {
			axios.put("/chatrooms", chatroom)
				.then((response) => {
					//Quickblox code started here
					if(response.status == 200)
					{            
						var APPLICATION_ID = axios.defaults.QB_APPLICATION_ID;
						var AUTH_KEY = axios.defaults.QB_AUTH_KEY;
						var AUTH_SECRET = axios.defaults.QB_AUTH_SECRET;
						var ACCOUNT_KEY = axios.defaults.QB_ACCOUNT_KEY;
						var CONFIG = axios.defaults.QB_CONFIG;
						var qb_login = axios.defaults.QB_LOGIN;
						var qb_pwd = axios.defaults.QB_PWD;           
						var qb_group_id = response.data.qb_group_id;

						QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);

						var params = { login: qb_login, password: qb_pwd };

						QB.createSession(params, function(error, result) {
							// callback function          
							if(qb_group_id != "0"){
								var userCredentials = {
									userId: result.user_id,
									password: qb_pwd
								};
                  
								QB.chat.connect(userCredentials, function(con_error, contactList) {                                                      
									var toUpdateParams = {
										name: response.data.name
									};

									QB.chat.dialog.update(qb_group_id, toUpdateParams, function(update_error, update_dialog) {                   
										QB.chat.disconnect();
										QB.destroySession(function(error) {});
									});
  
								});
							}             
						}); 
					}
					//Quickblox code ended here
					// commit('UPDATE_CHATROOM', response.data)
					commit("UPDATE_CHATROOM", Object.assign(chatroom, response.data));
					router.push("/chatroom/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteChatroom ({ commit }, chatroom_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/chatrooms?roomId="+chatroom_id)
				.then((response) => {
					commit("DELETE_CHATROOM", chatroom_id);
					router.push("/chatroom/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
	removeImage({ commit }, obj) {
		return new Promise((resolve) => {  
			var data = new FormData();
			data.append("bucketName", "chatrooms");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var removeImage = new FormData();
			removeImage.append("image", obj.oldImage);
			var object = {};
			removeImage.forEach((value, key) => object[key] = value);
			var json = JSON.stringify(object);
			var xhr = new XMLHttpRequest();
			axios.post("/fileremove", json);
			var xhr = new XMLHttpRequest();
      
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState === 4 && this.status === 200) {
					const jsonResponse = JSON.parse(this.responseText);
					commit("REPLACE_UPLOAD_CHATROOM_IMAGE", {image_url:jsonResponse.url, org_id:obj.org_id});
					resolve(jsonResponse.url);
				}
			});
			xhr.open("POST", axios.defaults.baseURL + "/fileupload");
			xhr.send(data);
		});
	},  
	uploadImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "chatrooms");
			data.append("image", obj.image);
    
			var xhr = new XMLHttpRequest();
 
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.employee_id){
					}else if("my_profile" == obj.employee_id){
						const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};
						userInfoLocalStorage["image_url"] = jsonResponse.url;
						localStorage.setItem("userInfo", JSON.stringify(userInfoLocalStorage));
					}else{
						commit("UPLOAD_CHATROOM_IMAGE", {image_url:jsonResponse.url, employee_id:obj.employee_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},
};
