/*=========================================================================================
  File Name: moduleOffer.js
  Description: Offer Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleOfferState.js";
import mutations from "./moduleOfferMutations.js";
import actions from "./moduleOfferActions.js";
import getters from "./moduleOfferGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
