/*=========================================================================================
  File Name: moduleMediaActions.js
  Description: Media Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";

export default {
	setMediaSearchQuery ({ commit }, query) {
		commit("SET_MEDIA_SEARCH_QUERY", query);
	},
	fetchMedias ({ commit }) {
		const org_id = localStorage.getItem("org_id");
		return new Promise((resolve, reject) => {
			axios.get("/media/all?org_id="+org_id)
				.then((response) => {
					commit("SET_MEDIAS", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addMedia ({ commit }, media) {
		return new Promise((resolve, reject) => {
			axios.post("/media", media)
				.then((response) => {
					commit("ADD_MEDIA", Object.assign(media, response.data));
					router.push("/story/all").catch(() => {});
					var data = new FormData();
					data.append("category_name", response.data.category_name);
					data.append("sub_category_name", response.data.sub_category_name);
					data.append("title", response.data.title);
					data.append("short_desc", response.data.short_desc);
					data.append("long_desc", response.data.long_desc);
					data.append("tags", response.data.tags);
					data.append("media_type", response.data.media_type);
					data.append("thumb_url", response.data.thumb_url);
					data.append("media_url", response.data.media_url);
					data.append("status", response.data.status);
					data.append("created_at", response.data.created_at);
					data.append("id", response.data.media_id);
					var object = {};
					data.forEach((value, key) => object[key] = value);
					var json = JSON.stringify(object);
					axios.post("/media/search", json);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateMedia ({ commit }, media) {
		return new Promise((resolve, reject) => {
			axios.put("/media", media)
				.then((response) => {
					// commit('UPDATE_MEDIA', response.data)
					commit("UPDATE_MEDIA", Object.assign(media, response.data));
					router.push("/story/all").catch(() => {});
					var data = new FormData();
					data.append("category_name", response.data.category_name);
					data.append("sub_category_name", response.data.sub_category_name);
					data.append("title", response.data.title);
					data.append("short_desc", response.data.short_desc);
					data.append("long_desc", response.data.long_desc);
					data.append("tags", response.data.tags);
					data.append("media_type", response.data.media_type);
					data.append("thumb_url", response.data.thumb_url);
					data.append("media_url", response.data.media_url);
					data.append("status", response.data.status);
					data.append("created_at", response.data.created_at);
					data.append("id", response.data.media_id);
					var object = {};
					data.forEach((value, key) => object[key] = value);
					var json = JSON.stringify(object);
					axios.post("/media/search", json);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteMedia ({ commit }, media_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/media?mediaId=" + media_id)
				.then((response) => {
					commit("DELETE_MEDIA", media_id);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
	uploadMedia ({ commit }, obj) {
		var data = new FormData();
		const mediaName = obj.media.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g,"_").replace(/[_\s]/g, "_");
		data.append("fileName", mediaName);
		data.append("fileType", obj.media.type);
		data.append("bucketName", "media");
		data.append("media", obj.media);
		var object = {};
		data.forEach((value, key) => object[key] = value);
		var json = JSON.stringify(object);
		return new Promise((resolve, reject) => {
			axios.post("/mediaupload", json)
				.then((response) => {
					commit("UPLOAD_MEDIA_FILE", Object.assign(json, response.data));
					const url = response.data.uploadURL.split("?")[0];
					this.file = new File([this.file], mediaName, { type: obj.media.type });
					delete axios.defaults.headers.common["Authorization"];
					const response_url = url.replace(axios.defaults.asset_s3_url, axios.defaults.asset_url);
					axios.put(response.data.uploadURL, obj.media, {  }).then((response) => {
						resolve(response_url);
					}); 
				})
				.catch((error) => { reject(error); });
		});
	},
	removeImage({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "media");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var removeImage = new FormData();
			removeImage.append("image", obj.oldImage);
			var object = {};
			removeImage.forEach((value, key) => object[key] = value);
			var json = JSON.stringify(object);
			var xhr = new XMLHttpRequest();
			axios.post("/fileremove", json);
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.media_id){
					}else{
						commit("UPLOAD_MEDIA_IMAGE", {thumb_url:jsonResponse.url, media_id:obj.media_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	}, 
	removeMedia ({ commit }, obj) {
		var data = new FormData();
		const getmediaName = obj.media.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g,"_").replace(/[_\s]/g, "_");
		data.append("fileName", getmediaName);
		data.append("fileType", obj.media.type);
		data.append("bucketName", "media");
		data.append("media", obj.media);
		data.append("image", obj.image);
		var object = {};
		data.forEach((value, key) => object[key] = value);
		var json = JSON.stringify(object);
		return new Promise((resolve, reject) => {
			axios.post("/fileremove", json);
			axios.post("/mediaupload", json)
				.then((response) => {
					commit("UPLOAD_MEDIA_FILE", Object.assign(json, response.data));
					const url = response.data.uploadURL.split("?")[0];
					this.file = new File([this.file], getmediaName, { type: obj.media.type });
					delete axios.defaults.headers.common["Authorization"];
					const response_url = url.replace(axios.defaults.asset_s3_url, axios.defaults.asset_url);
					axios.put(response.data.uploadURL, obj.media, {  }).then((response) => {
						resolve(response_url);
					}); 
				})
				.catch((error) => { reject(error); });
		});
	}, 
	uploadrecmdImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "media");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.media_id){
					}else{
						commit("UPLOAD_MEDIA_RECMD_IMAGE", {recmd_url:jsonResponse.url, media_id:obj.media_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},uploadrecmdImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "media");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.media_id){
					}else{
						commit("UPLOAD_MEDIA_RECMD_IMAGE", {recmd_url:jsonResponse.url, media_id:obj.media_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},
	uploadrecmdImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "media");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.media_id){
					}else{
						commit("UPLOAD_MEDIA_RECMD_IMAGE", {recmd_url:jsonResponse.url, media_id:obj.media_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},
	uploadImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "media");
			data.append("image", obj.image);
			var xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.employee_id){
					}else if("my_profile" == obj.employee_id){
						const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};
						userInfoLocalStorage["image_url"] = jsonResponse.url;
						localStorage.setItem("userInfo", JSON.stringify(userInfoLocalStorage));
					}else{
						commit("UPLOAD_MEDIA_IMAGE", {image_url:jsonResponse.url, tag_id:obj.tag_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},
};
