/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_MEDIA_SEARCH_QUERY (state, query) {
		state.mediaSearchQuery = query;
	},
	UPDATE_MEDIA_FILTER (state, filter) {
		state.mediaFilter = filter;
	},

	// API
	SET_MEDIAS (state, medias) {
		state.medias = medias;
	},
	ADD_MEDIA (state, media) {
		state.medias.unshift(media);
	},
	UPDATE_MEDIA (state, media) {
		const mediaIndex = state.medias.findIndex((t) => t.media_id === media.media_id);
		Object.assign(state.medias[mediaIndex], media);
	},
	DELETE_MEDIA (state, media_id) {
		const mediaIndex = state.medias.findIndex((t) => t.media_id === media_id);
		state.medias.splice(mediaIndex,1);
	},
	UPLOAD_MEDIA_IMAGE (state, obj) {
		const mediaIndex = state.medias.findIndex((t) => {
			const tmp = parseInt(obj.media_id);
			return t.media_id === tmp;
		});
		state.medias[mediaIndex]["thumb_url"]=obj.thumb_url;
	},
	UPLOAD_MEDIA_FILE (state, obj) {
		// const mediaIndex = state.medias.findIndex((t) => {
		//   const tmp = parseInt(obj.media_id)
		//   return t.media_id === tmp
		// })
		// state.medias[mediaIndex]['uploadURL']=obj.uploadURL
	},
	UPLOAD_MEDIA_RECMD_IMAGE (state, obj) {
		const mediaIndex = state.medias.findIndex((t) => {
			const tmp = parseInt(obj.media_id);
			return t.media_id === tmp;
		});
		state.medias[mediaIndex]["recmd_url"]=obj.recmd_url;
	},
	REMOVE_MEDIA_IMAGE (state, obj) {},
	REMOVE_MEDIA_DOC (state, obj) {},
	ADD_SEARCH_MEDIA(){state, obj;},
};
