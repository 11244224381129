/*=========================================================================================
  File Name: moduleChangepassword.js
  Description: Changepassword Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleChangepasswordState.js";
import mutations from "./moduleChangepasswordMutations.js";
import actions from "./moduleChangepasswordActions.js";
import getters from "./moduleChangepasswordGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
