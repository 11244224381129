/*=========================================================================================
  File Name: moduleEmployee.js
  Description: Employee Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleEmployeeState.js";
import mutations from "./moduleEmployeeMutations.js";
import actions from "./moduleEmployeeActions.js";
import getters from "./moduleEmployeeGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
