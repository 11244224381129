/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_MODULE_SEARCH_QUERY (state, query) {
		state.moduleSearchQuery = query;
	},
	UPDATE_MODULE_FILTER (state, filter) {
		state.moduleFilter = filter;
	},

	// API
	SET_MODULES (state, modules) {
		state.modules = modules;
	},
	ADD_MODULE (state, module) {
		state.modules.unshift(module);
	},
	UPDATE_MODULE (state, module) {
		const moduleIndex = state.modules.findIndex((t) => t.module_id === module.module_id);
		Object.assign(state.modules[moduleIndex], module);
	},
	DELETE_MODULE (state, module_id) {
		const moduleIndex = state.modules.findIndex((t) => t.module_id === module_id);
		state.modules.splice(moduleIndex,1);
	}
};
