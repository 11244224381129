/*=========================================================================================
  File Name: moduleBenefitActions.js
  Description: Benefit Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";


export default {
	setBenefitSearchQuery ({ commit }, query) {
		commit("SET_BENEFIT_SEARCH_QUERY", query);
	},
	fetchBenefits ({ commit }) {
		const org_id = localStorage.getItem("org_id");
		return new Promise((resolve, reject) => {
			axios.get("/benefits/all?org_id="+org_id)
				.then((response) => {
					commit("SET_BENEFITS", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addBenefit ({ commit }, benefit) {
		return new Promise((resolve, reject) => {
			axios.post("/benefits", benefit)
				.then((response) => {
					commit("ADD_BENEFIT", Object.assign(benefit, response.data));
					router.push("/benefit/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateBenefit ({ commit }, benefit) {
		return new Promise((resolve, reject) => {
			axios.put("/benefits", benefit)
				.then((response) => {
					commit("UPDATE_BENEFIT",Object.assign(benefit, response.data));
					router.push("/benefit/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteBenefit ({ commit }, benefit_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/benefits?benefitId="+benefit_id)
				.then((response) => {
					commit("DELETE_BENEFIT", benefit_id);
					router.push("/benefit/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	uploadDocument ({ commit }, obj) {
		var data = new FormData();
		const docName = obj.doc.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g,"_").replace(/[_\s]/g, "_");
		data.append("fileName", docName);
		data.append("fileType", obj.doc.type);
		data.append("bucketName", "benefits");
		data.append("media", obj.doc);
		var object = {};
		data.forEach((value, key) => object[key] = value);
		var json = JSON.stringify(object);
		return new Promise((resolve, reject) => {
			axios.post("/mediaupload", json)
				.then((response) => {
					commit("UPLOAD_BENEFIT_DOC", Object.assign(json, response.data));
					const url = response.data.uploadURL.split("?")[0];
					this.file = new File([this.file], docName, { type: obj.doc.type });
					delete axios.defaults.headers.common["Authorization"];
					axios.put(response.data.uploadURL, obj.doc, {  }); 
					const response_url = url.replace(axios.defaults.asset_s3_url, axios.defaults.asset_url);
					resolve(response_url);
				})
				.catch((error) => { reject(error); });
		});
	},
	removeImage({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "benefits");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var removeImage = new FormData();
			removeImage.append("image", obj.oldImage);
			var object = {};
			removeImage.forEach((value, key) => object[key] = value);
			var json = JSON.stringify(object);
			var xhr = new XMLHttpRequest();
			axios.post("/fileremove", json);
			var xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.employee_id){
					}else if("my_profile" == obj.employee_id){
						const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};
						userInfoLocalStorage["image_url"] = jsonResponse.url;
						localStorage.setItem("userInfo", JSON.stringify(userInfoLocalStorage));
					}else{
						commit("UPLOAD_BENEFIT_IMAGE", {image_url:jsonResponse.url, employee_id:obj.employee_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	}, 
	removeDocument ({ commit }, obj) {
		var data = new FormData();
		const docName = obj.doc.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g,"_").replace(/[_\s]/g, "_");
		data.append("fileName", docName);
		data.append("fileType", obj.doc.type);
		data.append("bucketName", "benefits");
		data.append("benefits", obj.doc);
		var object = {};
		data.forEach((value, key) => object[key] = value);
		var json = JSON.stringify(object);
		var removeImage = new FormData();
		removeImage.append("image", obj.oldDoc);
		var object = {};
		removeImage.forEach((value, key) => object[key] = value);
		var removeDoc = JSON.stringify(object);
		axios.post("/fileremove", removeDoc);
		return new Promise((resolve, reject) => {
			axios.post("/mediaupload", json)
				.then((response) => {
					commit("UPLOAD_BENEFIT_DOC", Object.assign(json, response.data));
					const url = response.data.uploadURL.split("?")[0];
					this.file = new File([this.file], docName, { type: obj.doc.type });
					delete axios.defaults.headers.common["Authorization"];
					axios.put(response.data.uploadURL, obj.doc, {  }); 
					const response_url = url.replace(axios.defaults.asset_s3_url, axios.defaults.asset_url);
					resolve(response_url);
				})
				.catch((error) => { reject(error); });
		});
	},
	removeMedia({ commit }, obj) {
		return new Promise((resolve, reject) => {
			axios.post("/fileremove", obj)
				.then((response) => {
					commit("REMOVE_BENEFIT_DOC", Object.assign(obj, response.data));
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
	uploadImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {
			let photo = obj.image;
			let req = new XMLHttpRequest();
			req.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.benefit_id){
					}else{
						commit("UPLOAD_BENEFIT_IMAGE", {image_url:jsonResponse.url, benefit_id:obj.benefit_id});
					}
					resolve(jsonResponse.url);
				}
			};
			let formData = new FormData();

			formData.append("bucketName", "benefits");
			formData.append("file_name", "image");
			formData.append("image", photo);
			req.open("POST", axios.defaults.baseURL+"/fileupload");
			req.setRequestHeader("Authorization", localStorage.getItem("idToken"));
			req.send(formData);
		});
	},
};
