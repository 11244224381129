// axios
import axios from "axios";
window.onunload = () => {
	// Clear the local storage
	window.MyStorage.clear();
};
//const baseURL = 'http://localhost:3000';

//Production
const baseURL = "https://2enb45wz7k.execute-api.ap-south-1.amazonaws.com/Development";
const asset_url = "https://assets.isabel.sg/";
const asset_s3_url = "https://isabel-image.s3.ap-south-1.amazonaws.com/";
const Pool_Id = "ap-south-1_55gbvp7gI";
const client_id = "44vv1svhpo8s5dhml45cshknc9";

//Development
//const b./aseURL = 'https://b59tztzzv6.execute-api.ap-south-1.amazonaws.com/development';
//const asset_url = 'https://dev-assets.isabel.sg/';
//const asset_s3_url = 'https://dev-isabel-image.s3.ap-south-1.amazonaws.com/';
//const Pool_Id = 'ap-south-1_eCPzGqJl8';
//const client_id = '172t8hm3gc5rtvma8prpb0p5rq';

//Testing
// const baseURL = 'https://v7yjrh1rob.execute-api.ap-south-1.amazonaws.com/testing';
// const asset_url = 'https://test-assets.isabel.sg/';
// const asset_s3_url = 'https://test-isabel-image.s3.ap-south-1.amazonaws.com/';
// const Pool_Id = 'ap-south-1_lMQxDkl35';
// const client_id = '4463kntl2e79j4kj0svh58l0eh';

//QuickBlox Config data
var QB_APPLICATION_ID = 89237;
var QB_AUTH_KEY = "GQbAQCtgdu4Acs7";
var QB_AUTH_SECRET = "HxusONxZnvOEB3X";
var QB_ACCOUNT_KEY = "Uiw7SSxQ3h7mf3DsR1sF";
var QB_CONFIG = { debug: true };
var QB_LOGIN = "lodhaad";
var QB_PWD = "Isa123@bel";
var QB_CHAT_TMP_PWD = "IsabelChat@2021";

export default axios.create({
	client_id,
	Pool_Id,
	baseURL,
	asset_url,
	asset_s3_url,
	QB_APPLICATION_ID,
	QB_AUTH_KEY,
	QB_AUTH_SECRET,
	QB_ACCOUNT_KEY,
	QB_CONFIG,
	QB_LOGIN,
	QB_PWD,
	QB_CHAT_TMP_PWD
	// You can add your headers here
});
