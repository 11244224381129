/*=========================================================================================
  File Name: moduleEmployeeActions.js
  Description: Employee Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";
import jwt from "../../http/requests/auth/jwt/index.js";

export default {
	setEmployeeSearchQuery ({ commit }, query) {
		commit("SET_EMPLOYEE_SEARCH_QUERY", query);
	},
	fetchEmployees ({ commit }) {
		const org_id = localStorage.getItem("org_id");
		return new Promise((resolve, reject) => {
			//axios.get('/employees/all')
			axios.get("/employees/all?org_id="+org_id)
				.then((response) => {
					commit("SET_EMPLOYEES", response.data);
					// commit('SET_EMPLOYEES', response.data[0].employees)
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addEmployee ({ commit }, employee) {
		return new Promise((resolve, reject) => {
			axios.post("/signup", employee)
				.then((response) => {
					//Quickblox code started here
					if(response.status == 200)
					{     
						var APPLICATION_ID = axios.defaults.QB_APPLICATION_ID;
						var AUTH_KEY = axios.defaults.QB_AUTH_KEY;
						var AUTH_SECRET = axios.defaults.QB_AUTH_SECRET;
						var ACCOUNT_KEY = axios.defaults.QB_ACCOUNT_KEY;
						var CONFIG = axios.defaults.QB_CONFIG;
						var qb_login = axios.defaults.QB_LOGIN;
						var qb_pwd = axios.defaults.QB_PWD;
						var userData = JSON.parse(localStorage.getItem("userInfo"));
						var qb_user_id = userData.qb_user_id;
						var org_emp_id = response.data.employee_id;
						var length = 10;
						var result           = [];
						var characters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
						var charactersLength = characters.length;
						for ( var i = 0; i < length; i++ ) {
							result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
						}
						var qb_login_name =result.join("");// userData.first_name.replace(/[^A-Z0-9]+/ig, "");
 
 
						QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);
 
						var params = { login: qb_login, password: qb_pwd };
 
						QB.createSession(params, function(error, result) {
							// callback function              
							if(1 == 1){
								//If Organization is creating the chatroom first time we need to create them as admin user in Qulickblox
								var create_user_params = {
									login: qb_login_name,
									password: axios.defaults.QB_CHAT_TMP_PWD,
									full_name: qb_login_name,
									email:response.data.email
								};
                 
								QB.users.create(create_user_params, function(create_user_error, create_user_result) {
									if (create_user_error) {
										// console.log("Create user error: " + JSON.stringify(create_user_error));
										if(create_user_error.code == 422){
											var RandNo = Math.floor(Math.random() * 9999) + 1;
											var create_user_params = {
												login: qb_login_name+RandNo,
												password: axios.defaults.QB_CHAT_TMP_PWD,
												full_name: qb_login_name,
												email:response.data.email
 
											};
                       
											QB.users.create(create_user_params, function(create_user_error1, create_user_result1) {
												qb_user_id = create_user_result1.id;  
												if(qb_user_id != 0)
												{
													userData.qb_user_id = qb_user_id;
													localStorage.setItem("userInfo", JSON.stringify(userData));
													//Update QB data user data to DB
													var qbuserdata = {};
													qbuserdata["update_type"] = 1;
													qbuserdata["update_prime_id"] = org_emp_id;
													qbuserdata["qb_user_id"] = qb_user_id;
													qbuserdata["qb_login"] = qb_login_name+RandNo;
													qbuserdata["qb_pwd"] = axios.defaults.QB_CHAT_TMP_PWD;
													//console.log(qbuserdata);
													axios.post("/chatrooms/quickblox", qbuserdata).then((qbuserdata_response) => {
														//console.log(qbuserdata_response);
													});
													//Update QB data user data to DB end
 
													var userCredentials = {
														userId: result.user_id,
														password: qb_pwd
													};
                             
													QB.chat.connect(userCredentials, function(error, contactList) {
                             
														var params = {
															type: 2,
															occupants_ids: [qb_user_id],
															name: response.data.name
														};
             
             
													});
												}                        
											});
										}else{
											qb_user_id = create_user_result.id;      
											if(qb_user_id != 0)
											{
												userData.qb_user_id = qb_user_id;
												localStorage.setItem("userInfo", JSON.stringify(userData));
												//Update QB data user data to DB
												var qbuserdata = {};
												qbuserdata["update_type"] = 1;
												qbuserdata["update_prime_id"] = org_emp_id;
												qbuserdata["qb_user_id"] = qb_user_id;
												qbuserdata["qb_login"] = qb_login_name;
												qbuserdata["qb_pwd"] = axios.defaults.QB_CHAT_TMP_PWD;
												//console.log(qbuserdata);
												axios.post("/chatrooms/quickblox", qbuserdata).then((qbuserdata_response) => {
													// console.log(qbuserdata_response);
												});
												//Update QB data user data to DB end
 
												var userCredentials = {
													userId: result.user_id,
													password: qb_pwd
												};
                             
												QB.chat.connect(userCredentials, function(error, contactList) {
                             
													var params = {
														type: 2,
														occupants_ids: [qb_user_id],
														name: response.data.name
													};
             
             
												});
											}
										}                          
									} else {
										qb_user_id = create_user_result.id;
										if(qb_user_id != 0)
										{
											userData.qb_user_id = qb_user_id;
											localStorage.setItem("userInfo", JSON.stringify(userData));
											var userCredentials = {
												userId: result.user_id,
												password: qb_pwd
											};
                         
											QB.chat.connect(userCredentials, function(error, contactList) {
                         
												var params = {
													type: 2,
													occupants_ids: [qb_user_id],
													name: response.data.name
												};
											});
										}
									}
								});
							}else{
								var userCredentials = {
									userId: result.user_id,
									password: qb_pwd
								};
                   
								QB.chat.connect(userCredentials, function(error, contactList) {                  
									var params = {
										type: 2,
										occupants_ids: [qb_user_id],
										name: response.data.name
									};
   
								});
							}             
						}); 
					}
					//Quickblox code ended here 
					commit("ADD_EMPLOYEE", Object.assign(employee, response.data));
					router.push("/employee/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateEmployee ({ commit }, employee) {
		return new Promise((resolve, reject) => {
			axios.put("/profile", employee)
				.then((response) => {
					commit("UPDATE_EMPLOYEE", response.data);
					router.push("/employee/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
	insertimportEmployee({ commit }, obj){
		var data = new FormData();
		data.append("csv", obj.csv);
		return new Promise((resolve, reject) => {
			axios.post("/employees/import", data)
				.then((response) => {
					commit("IMPORT_EMPLOYEE", Object.assign(data, response.data));
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
	updateProfile ({ commit }, employee) {
		return new Promise((resolve, reject) => {
			axios.put("/profile", employee)
				.then((response) => {
					localStorage.setItem("userInfo", JSON.stringify(response.data));
					localStorage.clear();
					router.push("/login").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
  
	changePassword ({ commit }, password) {
		console.log("password");
		console.log(password);
		return new Promise((resolve, reject) => {
			jwt.changePassword(password.oldPassword, password.newPassword)
				.then(response => {
				});
		});
	},  
 
	deleteEmployee ({ commit }, employee_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/employees?employeeId="+employee_id)
				.then((response) => {
					commit("DELETE_EMPLOYEE", employee_id);
					router.push("/employee/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
	removeImage({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "employees");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var removeImage = new FormData();
			removeImage.append("image", obj.oldImage);
			var object = {};
			removeImage.forEach((value, key) => object[key] = value);
			var json = JSON.stringify(object);
			var xhr = new XMLHttpRequest();
			axios.post("/fileremove", json);
			var xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.employee_id){
					}else if("my_profile" == obj.employee_id){
						const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};
						userInfoLocalStorage["image_url"] = jsonResponse.url;
						localStorage.setItem("userInfo", JSON.stringify(userInfoLocalStorage));
					}else{
						commit("UPLOAD_EMPLOYEE_IMAGE", {image_url:jsonResponse.url, employee_id:obj.employee_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	}, 
	uploadImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "employees");
			data.append("image", obj.image);
			var xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.employee_id){
					}else if("my_profile" == obj.employee_id){
						const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};
						userInfoLocalStorage["image_url"] = jsonResponse.url;
						localStorage.setItem("userInfo", JSON.stringify(userInfoLocalStorage));
					}else{
						commit("UPLOAD_EMPLOYEE_IMAGE", {image_url:jsonResponse.url, employee_id:obj.employee_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},  
};
