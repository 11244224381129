/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedEvents: state => state.events.filter((event) => {
		return true;
	}),
	getEvent: state => eventId => state.events.find((event) => {
		const tmp = parseInt(eventId);
		return event.event_id === tmp;
	})
};
