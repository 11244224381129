/*=========================================================================================
  File Name: moduleTag.js
  Description: Tag Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleTagState.js";
import mutations from "./moduleTagMutations.js";
import actions from "./moduleTagActions.js";
import getters from "./moduleTagGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
