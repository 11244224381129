/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedCategories: state => state.categories.filter((category) => {
		return true;
	}),
	queriedCategories_offers: state => state.categories.filter((category) => {
		if(category.module_id== 2 && category.module_id == 2 && category.status == 1 && category.parent_id !==0){
			return true;
		}
	}),
	getActiveCategories: state => state.categories.filter((category) => {
		if(category.status==1){
			return true;
		}
		return false;
	}),
	getMainCategories: state => moduleId => state.categories.filter((category) => {
		if(category.module_id==moduleId && category.parent_id==0 && category.status == 1){
			return true;
		}
		return false;
	}),
	getMainCategories_media: state => moduleId => state.categories.filter((category) => {
		if(category.module_id==moduleId && category.parent_id==0 && category.status == 1 && moduleId == 1){
			if(category.category_id == 1){
				return true;
			}
			return false;
		}else if(category.module_id==moduleId && category.parent_id==0 && category.status == 1 && moduleId == 3){
			if(category.category_id == 5){
				return true;
			}
			return false;
		}
		return false;
	}),
	getSubCategories: state => categoryId => state.categories.filter((category) => {
		if(category.parent_id==categoryId && category.parent_id!=0 && category.status == 1){
			return true;
		}
		return false;
	}),
	getCategoryName: state => categoryId => state.categories.find((category) => {
		if(category.category_id === categoryId && category.module_id == 2){
			return true;
		}
		return false;
	})?state.categories.find((category) => {
			if(category.category_id === categoryId){
				return true;
			}
			return false;
		})["category_name"]:"N/A",
	getCategory: state => categoryId => state.categories.find((category) => category.category_id === categoryId),
	getObj:state => (moduleId,categoryId) => state.categories.find()
};
