/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {

	// API
	SET_DASHBOARD (state, dashboard) {
		state.dashboard = dashboard;
		state.is_loaded = true;
	},

	SET_DASHBOARD2 (state, dashboard) {
		state.dashboard2 = dashboard;
		state.is_loaded2 = true;
	},
};
