/*=========================================================================================
  File Name: moduleModule.js
  Description: Module Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleModuleState.js";
import mutations from "./moduleModuleMutations.js";
import actions from "./moduleModuleActions.js";
import getters from "./moduleModuleGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
