/*=========================================================================================
  File Name: moduleChatroom.js
  Description: Chatroom Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleChatroomState.js";
import mutations from "./moduleChatroomMutations.js";
import actions from "./moduleChatroomActions.js";
import getters from "./moduleChatroomGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
