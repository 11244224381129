/*=========================================================================================
  File Name: moduleBenefit.js
  Description: Benefit Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleBenefitState.js";
import mutations from "./moduleBenefitMutations.js";
import actions from "./moduleBenefitActions.js";
import getters from "./moduleBenefitGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
