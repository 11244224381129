/*=========================================================================================
  File Name: moduleEventActions.js
  Description: Event Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";

export default {
	setEventSearchQuery ({ commit }, query) {
		commit("SET_EVENT_SEARCH_QUERY", query);
	},
	fetchEvents ({ commit }) {
		const org_id = localStorage.getItem("org_id");

		return new Promise((resolve, reject) => {
			axios.get("/events/all?org_id="+org_id)
				.then((response) => {
					commit("SET_EVENTS", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addEvent ({ commit }, event) {
		return new Promise((resolve, reject) => {
			axios.post("/events", event)
				.then((response) => {
					commit("ADD_EVENT", Object.assign(event, response.data));
					router.push("/event/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateEvent ({ commit }, event) {
		return new Promise((resolve, reject) => {
			axios.put("/events", event)
				.then((response) => {
					commit("UPDATE_EVENT", Object.assign(event, response.data));
					router.push("/event/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteEvent ({ commit }, event_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/events?event_id="+event_id)
				.then((response) => {
					commit("DELETE_EVENT", event_id);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	uploadImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "events");
			data.append("file_name", "image");
			data.append("image", obj.image);
    
			var xhr = new XMLHttpRequest();
 
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.employee_id){
					}else if("my_profile" == obj.employee_id){
						const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};
						userInfoLocalStorage["image_url"] = jsonResponse.url;
						localStorage.setItem("userInfo", JSON.stringify(userInfoLocalStorage));
					}else{
						commit("UPLOAD_EVENT_IMAGE", {image_url:jsonResponse.url, event_id:obj.event_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},  
	removeImage({ commit }, obj) {
		return new Promise((resolve) => {  
			var data = new FormData();
			data.append("bucketName", "events");
			data.append("file_name", "image");
			data.append("image", obj.image);
			var removeImage = new FormData();
			removeImage.append("image", obj.oldImage);
			var object = {};
			removeImage.forEach((value, key) => object[key] = value);
			var json = JSON.stringify(object);
			var xhr = new XMLHttpRequest();
			axios.post("/fileremove", json);
			var xhr = new XMLHttpRequest();
      
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState === 4 && this.status === 200) {
					const jsonResponse = JSON.parse(this.responseText);
					commit("REPLACE_UPLOAD_EVENT_IMAGE", {image_url:jsonResponse.url, org_id:obj.org_id});
					resolve(jsonResponse.url);
				}
			});
			xhr.open("POST", axios.defaults.baseURL + "/fileupload");
			xhr.send(data);
		});
	}, 
};
