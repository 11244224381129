/*=========================================================================================
  File Name: moduleModuleActions.js
  Description: Module Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
	setModuleSearchQuery ({ commit }, query) {
		commit("SET_MODULE_SEARCH_QUERY", query);
	},
	fetchModules ({ commit }) {
		const org_id = localStorage.getItem("org_id");
		return new Promise((resolve, reject) => {
			axios.get("/modules/all?org_id="+org_id)
				.then((response) => {
					commit("SET_MODULES", response.data.modules);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addModule ({ commit }, module) {
		return new Promise((resolve, reject) => {
			axios.post("/modules", module)
				.then((response) => {
					commit("ADD_MODULE", Object.assign(module, response.data));
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateModule ({ commit }, module) {
		return new Promise((resolve, reject) => {
			axios.put("/modules", module)
				.then((response) => {
					commit("UPDATE_MODULE", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteModule ({ commit }, module_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/modules?moduleId="+module_id)
				.then((response) => {
					commit("DELETE_MODULE", module_id);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	}
};
