/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	getCountryName: state => countryId => state.countries.find((country) => country.country_id === countryId)?state.countries.find((country) => country.country_id === countryId)["country_name"]:"Empty",
	queriedCountries: state => state.countries.filter((country) => {
		let isItemOfCurrentFilter = false;

		if (
			(state.countryFilter === "all" && !country.isTrashed)
          || (state.countryFilter === "important" && !country.isTrashed && country.isImportant)
          || (state.countryFilter === "starred" && !country.isTrashed && country.isStarred)
          || (state.countryFilter === "completed" && !country.isTrashed && country.isCompleted)
          || (state.countryFilter === "trashed" && country.isTrashed)

		) { isItemOfCurrentFilter = true; }

		return true || isItemOfCurrentFilter && (country.country_name.toLowerCase().includes(state.countrySearchQuery.toLowerCase()) || country.country_code.toLowerCase().includes(state.countrySearchQuery.toLowerCase()));
	}),
	getActiveCountries: state => state.countries.filter((country) => {
		return country.country_status=="1"?true:false;
	}),
	getCountry: state => countryId => state.countries.find((country) => country.country_id === countryId)
};
