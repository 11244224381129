/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedOffers: state => state.offers.filter((offer) => {
		return true;
	}),
	getActiveOffers: state => state.offers.filter((offer) => {
		if(offer.status==1){
			return true;
		}
		return false;
	}),
	getOffer: state => offerId => state.offers.find((offer) => {
		let temp = parseInt(offerId);
		if(offer.offer_id == temp){
			return true;
		}
	}),
	getOfferName: state => offerId => state.offers.find((offer) => {
		let temp = parseInt(offerId);
		if(offer.offer_id == temp){
			return true;
		}
	})?state.offers.find((offer) => {
			let temp = parseInt(offerId);
			if(offer.offer_id == temp){
				return true;
			}
		})["title"]:"Empty",
};
