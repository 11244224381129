/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	UPDATE_PASSWORD (state, tag) {
		const changepasswordIndex = state.changepasswords.findIndex((t) => t.password_id === password.password_id);
		Object.assign(state.changepasswords[passwordIndex], tag);
	},
};
