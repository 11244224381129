var AmazonCognitoIdentity = require("amazon-cognito-identity-js");

import axios from "../../../axios/index.js";
import store from "../../../../store/store.js";
import axios1 from "../../../../axios.js";

const PoolId = axios1.defaults.Pool_Id;
const ClientId = axios1.defaults.client_id;

export default {
	init () {
		if(localStorage.getItem("idToken")){
			axios.defaults.headers.common["Authorization"] = localStorage.getItem("idToken");
		}
	},
	aws_id_token (email, pwd) {
		var authenticationData = {
			Username : email,//'trilok1@mailinator.com',
			Password : pwd,//'trilok@123',
		};
		var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
		var poolData = {
			UserPoolId : PoolId,
			ClientId : ClientId
		};
		var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
		var userData = {
			Username : email,
			Pool : userPool
		};
		var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

		return new Promise((resolve, reject) => {
			cognitoUser.authenticateUser(authenticationDetails, {
				onSuccess: function (result) {
					var accessToken = result.getAccessToken().getJwtToken();
					var idToken = result.getIdToken().getJwtToken();
					var refreshToken = result.getRefreshToken().getToken();
					resolve(result);
				},
				onFailure: function(err) {
					reject(err);
				},
   
			});
		});
	},

	changePassword ( newPwd,oldPwd) {
		var authenticationData = {
			Username : localStorage.getItem("email"),
			Password : oldPwd,
		};
		var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
		var poolData = {
			UserPoolId : PoolId,
			ClientId : ClientId
		};
		var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
		var userData = {
			Username : localStorage.getItem("email"),
			Pool     : userPool
		};
		var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
		return new Promise((resolve, reject) => {
			cognitoUser.authenticateUser(authenticationDetails, {
				onSuccess: function (result) {
					cognitoUser.changePassword(oldPwd, newPwd, function(err, result) {
						if (err) {
							reject(err);
						}else{
							resolve(result);
						}
					});
				},
				onFailure: function(err) {
					reject(err);
				},

			});
		});
	},
  
	forgotPassword (email) {
		var poolData = {
			UserPoolId : PoolId,
			ClientId : ClientId
		};
		var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
		var userData = {
			Username : email,
			Pool : userPool
		};
		var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
		return new Promise((resolve, reject) => {
			cognitoUser.forgotPassword({
				onSuccess: function(data) {
					resolve(data);
				},
				onFailure: function(err) {
					reject(err);
				},
			});
		});
	},
	resetPassword (email, verificationCode, newPassword) {
		var poolData = {
			UserPoolId : PoolId,
			ClientId : ClientId
		};
		var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
		var userData = {
			Username : email,
			Pool : userPool
		};
		var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
		return new Promise((resolve, reject) => {
			cognitoUser.confirmPassword(verificationCode, newPassword, {
				onSuccess: function(data) {
					resolve(data);
				},
				onFailure: function(err) {
					reject(err);
				},
			});
		});
	},
	resetPassword (email, verificationCode, newPassword) {
		var poolData = {
			UserPoolId : PoolId,
			ClientId : ClientId
		};
		var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
		var userData = {
			Username : email,
			Pool : userPool
		};
		var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
		return new Promise((resolve, reject) => {
			cognitoUser.confirmPassword(verificationCode, newPassword, {
				onSuccess: function(data) {
					resolve(data);
				},
				onFailure: function(err) {
					reject(err);
				},
			});
		});
	},


	login (idToken) {
		return axios.get("/signin?login_Type=2", {
			headers: {
				Authorization: idToken
			}
		});
	},
	registerUser (name, email, pwd) {
		return axios.post("/signup", {
			displayName: name,
			email,
			password: pwd
		});
	},
	refreshToken () {
		return axios.post("/api/auth/refresh-token", {accessToken: localStorage.getItem("accessToKen")});
	},
	userInfo () {
		return axios.get("/signin?login_Type=2");
	}
};
