/*=========================================================================================
  File Name: moduleTagActions.js
  Description: Tag Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";


export default {
	setTagSearchQuery ({ commit }, query) {
		commit("SET_TAG_SEARCH_QUERY", query);
	},
	fetchTags ({ commit }) {
		const org_id = localStorage.getItem("org_id");
		return new Promise((resolve, reject) => {
			axios.get("/tags/all?org_id="+org_id)
				.then((response) => {
					commit("SET_TAGS", response.data.tags);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addTag ({ commit }, tag) {   
		return new Promise((resolve, reject) => {
			axios.post("/tags", tag)
				.then((response) => {
					commit("ADD_TAG", Object.assign(tag, response.data));
					router.push("/tag/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateTag ({ commit }, tag) {
		return new Promise((resolve, reject) => {
			axios.put("/tags", tag)
				.then((response) => {
					// commit('UPDATE_TAG', response.data)
					commit("UPDATE_TAG", Object.assign(tag, response.data));
					router.push("/tag/all").catch(() => {});
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteTag ({ commit }, tag_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/tags?tag_id="+tag_id)
				.then((response) => {
					commit("DELETE_TAG", tag_id);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},
	uploadImage ({ commit }, obj) {
		return new Promise((resolve, reject) => {  
			var data = new FormData();
			data.append("bucketName", "tags");
			data.append("image", obj.image);
    
			var xhr = new XMLHttpRequest();
 
			xhr.addEventListener("readystatechange", function() {
				if (this.readyState == 4 && this.status == 200) {
					var jsonResponse = JSON.parse(this.responseText);
					if(!obj.employee_id){
					}else if("my_profile" == obj.employee_id){
						const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};
						userInfoLocalStorage["image_url"] = jsonResponse.url;
						localStorage.setItem("userInfo", JSON.stringify(userInfoLocalStorage));
					}else{
						commit("UPLOAD_TAG_IMAGE", {image_url:jsonResponse.url, tag_id:obj.tag_id});
					}
					resolve(jsonResponse.url);
				}
			}); 
			xhr.open("POST", axios.defaults.baseURL+"/fileupload");
			xhr.send(data);
		});
	},  
};
