/*=========================================================================================
  File Name: moduleTagActions.js
  Description: Tag Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import router from "@/router";


export default {
	updatePassword ({ commit }, password) {
		return new Promise((resolve, reject) => {
			axios.put("/changepassword", password)
				.then((response) => {
					commit("UPDATE_PASSWORD", Object.assign(password, response.data));
				})
				.catch((error) => { reject(error); });
		});
	},    
};
