/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_OFFER_SEARCH_QUERY (state, query) {
		state.offerSearchQuery = query;
	},
	UPDATE_OFFER_FILTER (state, filter) {
		state.offerFilter = filter;
	},

	// API
	SET_OFFERS (state, offers) {
		state.offers = offers;
	},
	ADD_OFFER (state, offer) {
		state.offers.unshift(offer);
	},
	UPDATE_OFFER (state, offer) {
		const offerIndex = state.offers.findIndex((t) => t.offer_id === offer.offer_id);
		Object.assign(state.offers[offerIndex], offer);
	},
	DELETE_OFFER (state, offer_id) {
		const offerIndex = state.offers.findIndex((t) => t.offer_id === offer_id);
		state.offers.splice(offerIndex,1);
	},
	UPLOAD_OFFER_IMAGE (state, obj) {
		const offerIndex = state.offers.findIndex((t) => {
			const tmp = parseInt(obj.offer_id);
			return t.offer_id === tmp;
		});
		state.offers[offerIndex]["image_url"]=obj.image_url;
	},
	REPLACE_UPLOAD_OFFER_IMAGE(){}
};
