/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";


import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";

export default {
	loginAttempt ({ dispatch }, payload) {

		// New payload for login action
		const newPayload = {
			userDetails: payload.userDetails,
			notify: payload.notify,
			closeAnimation: payload.closeAnimation
		};

		// If remember_me is enabled change firebase Persistence
		if (!payload.checkbox_remember_me) {

			// Change firebase Persistence
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

			// If success try to login
				.then(function () {
					dispatch("login", newPayload);
				})

			// If error notify
				.catch(function (err) {

					// Close animation if passed as payload
					if (payload.closeAnimation) payload.closeAnimation();

					payload.notify({
						time: 2500,
						title: "Error",
						text: err.message,
						iconPack: "feather",
						icon: "icon-alert-circle",
						color: "danger"
					});
				});
		} else {
			// Try to login
			dispatch("login", newPayload);
		}
	},
	login ({ commit, state, dispatch }, payload) {

		// If user is already logged in notify and exit
		if (state.isUserLoggedIn()) {
			// Close animation if passed as payload
			if (payload.closeAnimation) payload.closeAnimation();

			payload.notify({
				title: "Login Attempt",
				text: "You are already logged in!",
				iconPack: "feather",
				icon: "icon-alert-circle",
				color: "warning"
			});

			return false;
		}

		// Try to sigin
		firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)

			.then((result) => {

				// Set FLAG username update required for updating username
				let isUsernameUpdateRequired = false;

				// if username is provided and updateUsername FLAG is true
				// set local username update FLAG to true
				// try to update username
				if (payload.updateUsername && payload.userDetails.displayName) {

					isUsernameUpdateRequired = true;

					dispatch("updateUsername", {
						user: result.user,
						username: payload.userDetails.displayName,
						notify: payload.notify,
						isReloadRequired: true
					});
				}

				// Close animation if passed as payload
				if (payload.closeAnimation) payload.closeAnimation();

				// if username update is not required
				// just reload the page to get fresh data
				// set new user data in localstorage
				if (!isUsernameUpdateRequired) {
					router.push(router.currentRoute.query.to || "/");
					commit("UPDATE_USER_INFO", result.user.providerData[0], {root: true});
				}
			}, (err) => {

				// Close animation if passed as payload
				if (payload.closeAnimation) payload.closeAnimation();

				payload.notify({
					time: 2500,
					title: "Error",
					text: err.message,
					iconPack: "feather",
					icon: "icon-alert-circle",
					color: "danger"
				});
			});
	},

	// Google Login
	loginWithGoogle ({commit, state}, payload) {
		if (state.isUserLoggedIn()) {
			payload.notify({
				title: "Login Attempt",
				text: "You are already logged in!",
				iconPack: "feather",
				icon: "icon-alert-circle",
				color: "warning"
			});
			return false;
		}
		const provider = new firebase.auth.GoogleAuthProvider();

		firebase.auth().signInWithPopup(provider)
			.then((result) => {
				router.push(router.currentRoute.query.to || "/");
				commit("UPDATE_USER_INFO", result.user.providerData[0], {root: true});
			}).catch((err) => {
				payload.notify({
					time: 2500,
					title: "Error",
					text: err.message,
					iconPack: "feather",
					icon: "icon-alert-circle",
					color: "danger"
				});
			});
	},

	// Facebook Login
	loginWithFacebook ({commit, state}, payload) {
		if (state.isUserLoggedIn()) {
			payload.notify({
				title: "Login Attempt",
				text: "You are already logged in!",
				iconPack: "feather",
				icon: "icon-alert-circle",
				color: "warning"
			});
			return false;
		}
		const provider = new firebase.auth.FacebookAuthProvider();

		firebase.auth().signInWithPopup(provider)
			.then((result) => {
				router.push(router.currentRoute.query.to || "/");
				commit("UPDATE_USER_INFO", result.user.providerData[0], {root: true});
			}).catch((err) => {
				payload.notify({
					time: 2500,
					title: "Error",
					text: err.message,
					iconPack: "feather",
					icon: "icon-alert-circle",
					color: "danger"
				});
			});
	},
	// Twitter Login
	loginWithTwitter ({commit, state}, payload) {
		if (state.isUserLoggedIn()) {
			payload.notify({
				title: "Login Attempt",
				text: "You are already logged in!",
				iconPack: "feather",
				icon: "icon-alert-circle",
				color: "warning"
			});
			return false;
		}
		const provider = new firebase.auth.TwitterAuthProvider();

		firebase.auth().signInWithPopup(provider)
			.then((result) => {
				router.push(router.currentRoute.query.to || "/");
				commit("UPDATE_USER_INFO", result.user.providerData[0], {root: true});
			}).catch((err) => {
				payload.notify({
					time: 2500,
					title: "Error",
					text: err.message,
					iconPack: "feather",
					icon: "icon-alert-circle",
					color: "danger"
				});
			});
	},
	forgotPasswordJWT ({ commit }, payload) {
		console.log(payload.userDetails.email);
		return new Promise((resolve, reject) => {
			jwt.forgotPassword(payload.userDetails.email)
				.then(response => {
					commit("UPDATE_RECOVERY_EMAIL", payload.userDetails.email);
					router.push("/pages/reset-password").catch(() => {});
					resolve(response);
				})
				.catch(error => { reject(error); });
		});
	},
	changePasswordJWT ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			jwt.changePassword(payload.newPassword,payload.oldPassword)
				.then(response => {
					commit("UPDATE_CHANGE_PASSWORD",response);
					router.push("/login").catch(() => {});
					localStorage.clear();
					resolve(response);
				})
				.catch(error => { reject(error); });
		});
	},
	resetPasswordJWT ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			jwt.resetPassword(payload.email, payload.verificationCode, payload.newPassword)
				.then(response => {
					router.push("/login").catch(() => {});
					resolve(response);
				})
				.catch(error => { reject(error); });
		});
	},

	// Github Login
	loginWithGithub ({commit, state}, payload) {
		if (state.isUserLoggedIn()) {
			payload.notify({
				title: "Login Attempt",
				text: "You are already logged in!",
				iconPack: "feather",
				icon: "icon-alert-circle",
				color: "warning"
			});
			return false;
		}
		const provider = new firebase.auth.GithubAuthProvider();

		firebase.auth().signInWithPopup(provider)
			.then((result) => {
				router.push(router.currentRoute.query.to || "/");
				commit("UPDATE_USER_INFO", result.user.providerData[0], {root: true});
			}).catch((err) => {
				payload.notify({
					time: 2500,
					title: "Error",
					text: err.message,
					iconPack: "feather",
					icon: "icon-alert-circle",
					color: "danger"
				});
			});
	},
	registerUser ({dispatch}, payload) {

		// create user using firebase
		firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
			.then(() => {
				payload.notify({
					title: "Account Created",
					text: "You are successfully registered!",
					iconPack: "feather",
					icon: "icon-check",
					color: "success"
				});

				const newPayload = {
					userDetails: payload.userDetails,
					notify: payload.notify,
					updateUsername: true
				};
				dispatch("login", newPayload);
			}, (error) => {
				payload.notify({
					title: "Error",
					text: error.message,
					iconPack: "feather",
					icon: "icon-alert-circle",
					color: "danger"
				});
			});
	},
	updateUsername ({ commit }, payload) {
		payload.user.updateProfile({
			displayName: payload.displayName
		}).then(() => {

			// If username update is success
			// update in localstorage
			const newUserData = Object.assign({}, payload.user.providerData[0]);
			newUserData.displayName = payload.displayName;
			commit("UPDATE_USER_INFO", newUserData, {root: true});

			// If reload is required to get fresh data after update
			// Reload current page
			if (payload.isReloadRequired) {
				router.push(router.currentRoute.query.to || "/");
			}
		}).catch((err) => {
			payload.notify({
				time: 8800,
				title: "Error",
				text: err.message,
				iconPack: "feather",
				icon: "icon-alert-circle",
				color: "danger"
			});
		});
	},


	// JWT
	loginJWT ({ commit }, payload) {

		return new Promise((resolve, reject) => {
			jwt.aws_id_token(payload.userDetails.email, payload.userDetails.password)
				.then(response => {
					var accessToken = response.getAccessToken().getJwtToken();
					var idToken = response.getIdToken().getJwtToken();
					var refreshToken = response.getRefreshToken().getToken();

					localStorage.setItem("accessToken", accessToken);
					localStorage.setItem("idToken", idToken);
					localStorage.setItem("refreshToken", refreshToken);

					// commit('SET_ACCESS_TOKEN', accessToken)
					commit("SET_ID_TOKEN", idToken);
					// commit('SET_REFRESH_TOKEN', refreshToken)

					jwt.login(idToken)
						.then(response=>{
							if(response.data.image_url === ""){
								localStorage.setItem("image_url", "/profileImage.png");
							}else{
								localStorage.setItem("image_url", response.data.image_url);
							}

							localStorage.setItem("org_id", response.data.org_id);
							localStorage.setItem("email", response.data.email);
							localStorage.setItem("org_name", response.data.org_name);

							localStorage.setItem("userInfo", JSON.stringify(response.data));
							commit("UPDATE_USER_INFO", response.data, {root: true});
							document.location.href="/insights";
							// router.push(router.currentRoute.query.to || '/dashboard')
							resolve(response);
						})
						.catch(error => { 
							reject(error); });
				})
				.catch(error => { 
					reject(error); });
		});

	},
	registerUserJWT ({ commit }, payload) {

		const { displayName, email, password, confirmPassword } = payload.userDetails;

		return new Promise((resolve, reject) => {

			// Check confirm password
			if (password !== confirmPassword) {
				reject({message: "Password doesn't match. Please try again."});
			}

			jwt.registerUser(displayName, email, password)
				.then(response => {
					// Redirect User
					router.push(router.currentRoute.query.to || "/");

					// Update data in localStorage
					localStorage.setItem("accessToken", response.data.accessToken);
					commit("UPDATE_USER_INFO", response.data.userData, {root: true});

					resolve(response);
				})
				.catch(error => { reject(error); });
		});
	},
	fetchUserInfo ({ commit }) {
		return new Promise((resolve, reject) => {
			jwt.userInfo()
				.then(response=>{
					localStorage.setItem("user_id", response.data.employee_id);
					commit("UPDATE_USER_INFO", response.data, {root: true});
					resolve(response);
				})
				.catch(error => { reject(error); });
		});
	},
	fetchAccessToken () {
		return new Promise((resolve) => {
			jwt.refreshToken().then(response => { resolve(response); });
		});
	}
};
