/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList";
import themeConfig, { colors } from "@/../themeConfig.js";

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////
window.onunload = () => {
	alert();
	// Clear the local storage
	window.MyStorage.clear();
};
// *From Auth - Data will be received from auth provider
const userDefaults = {
	employee_id     :2,
	country_id      :1,
	org_id          : 1,//localStorage.getItem('org_id'),
	employee_type   :3,
	first_name      :"Sunil",
	middle_name     :"Kumar",
	last_name       :"T",
	email           :"harish.m@deliforce.io",
	interest_ids    :"[]",
	image_url       :  localStorage.getItem("image_url"),//require('@/assets/images/portrait/small/avatar-s-1.jpg'),
	date_of_birth   :"2020-12-21T12:02:24.000Z",
	date_of_join    :"2021-01-06T10:07:30.000Z",
	weight          :"20",
	height          :"30",
	last_loggedin   :"2021-01-06T10:07:30.000Z",
	cognito_id      :localStorage.getItem("cognito_id"),//'5a2e5c28-b5e7-4520-a18d-437d9486ac4b',
	status          :"1"

};

const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
	const userInfo = {};

	// Update property in user
	Object.keys(userInfo).forEach((key) => {
		// If property is defined in localStorage => Use that
		userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key];
	});

	// Include properties from localStorage
	Object.keys(userInfoLocalStorage).forEach((key) => {
		if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key];
	});

	userInfo["displayName"] = [userInfo["first_name"],userInfo["middle_name"],userInfo["last_name"]].join(" ");
	userInfo["image_url"] = userDefaults["image_url"];

	return userInfo;
};

const orgIsabel = {
	org_id          :localStorage.getItem("org_id"),
	country_id      :1,
	type            :"c",
	name            :"iSabel",
	email           :"support@isabel.com",
	phone_number    :"754678",
	city            :"Bangalore",
	address         :"#44, Kengeri, B 66",
	about           :"lorem ipsum fdieo fske fse sd f lise fkjdlf lkdsjfljlkfj kdjf lsjf lsdjfl sdjfks dfsd gdf",
	image_url       :"/img/cover.21a4037c.jpg",
	website_url     :"https://www.isabel.com",
	status          :"1"
};


// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
	const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
	const mq = function (query) {
		return window.matchMedia(query).matches;
	};

	if ("ontouchstart" in window || window.DocumentTouch) {
		return true;
	}

	// include the 'heartz' as a way to have a non matching MQ to help terminate the join
	// https://git.io/vznFH
	const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
	return mq(query);
};


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
	AppActiveUser           : getUserInfo(),
	IsabelData              : orgIsabel,
	image_url               :  localStorage.getItem("image_url"),
	pps                     : 10,
	bodyOverlay             : false,
	isVerticalNavMenuActive : true,
	is_touch_device         : is_touch_device(),
	mainLayoutType          : themeConfig.mainLayoutType || "vertical",
	navbarSearchAndPinList,
	reduceButton            : themeConfig.sidebarCollapsed,
	verticalNavMenuWidth    : "default",
	verticalNavMenuItemsMin : false,
	scrollY                 : 0,
	starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
	theme                   : themeConfig.theme || "light",
	themePrimaryColor       : colors.primary,

	// Can be used to get current window with
	// Note: Above breakpoint state is for internal use of sidebar & navbar component
	windowWidth: null
};

export default state;
