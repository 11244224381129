/*=========================================================================================
  File Name: moduleMedia.js
  Description: Media Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleMediaState.js";
import mutations from "./moduleMediaMutations.js";
import actions from "./moduleMediaActions.js";
import getters from "./moduleMediaGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
