/*=========================================================================================
  File Name: moduleCategory.js
  Description: Category Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleCategoryState.js";
import mutations from "./moduleCategoryMutations.js";
import actions from "./moduleCategoryActions.js";
import getters from "./moduleCategoryGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
