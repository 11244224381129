/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedBenefits: state => state.benefits.filter((benefit) => {
		return true;
	}),
	getBenefit: state => benefitId => state.benefits.find((benefit) => benefit.id === benefitId)
};
