/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedEmployees: state => state.employees.filter((employee) => {
		return true;
	}),
	getEmployee: state => employeeId => state.employees.find((employee) => {
		let tmp = parseInt(employeeId);
		return employee.employee_id === tmp;
	}),
};
