/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_TAG_SEARCH_QUERY (state, query) {
		state.tagSearchQuery = query;
	},
	UPDATE_TAG_FILTER (state, filter) {
		state.tagFilter = filter;
	},

	// API
	SET_TAGS (state, tags) {
		state.tags = tags;
	},
	ADD_TAG (state, tag) {
		state.tags.unshift(tag);
	},
	UPDATE_TAG (state, tag) {
		const tagIndex = state.tags.findIndex((t) => t.tag_id === tag.tag_id);
		Object.assign(state.tags[tagIndex], tag);
	},
	DELETE_TAG (state, tag_id) {
		const tagIndex = state.tags.findIndex((t) => t.tag_id === tag_id);
		state.tags.splice(tagIndex,1);
	},

	UPLOAD_TAG_IMAGE (state, obj) {
		const tagIndex = state.tags.findIndex((t) => {
			const tmp = parseInt(obj.tag_id);
			return t.tag_id === tmp;
		});
		state.tags[tagIndex]["image_url"]=obj.image_url;
	}
};
