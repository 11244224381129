/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedModules: state => state.modules.filter((module) => {
		return true;
	}),
	getActiveModules_employee: state => state.modules.filter((module) => {
		return module.status=="1"?true:false;
	}),
	getActiveModules: state => state.modules.filter((module) => {
		if(module.module_id == 4 || module.module_id == 1){
			return module.status=="1"?true:false;
		}
	}),
	getActiveModules_media: state => state.modules.filter((module) => {
		if(module.module_id == 1 || module.module_id == 3){
			return module.status=="1"?true:false;
		}
	}),
	getInterestedModules: state => iids => state.modules.filter((module) => {
		if(module.status==1 && iids.indexOf(module.module_id)!=-1){
			return true;
		}
		return false;
	}),
	getModuleName: state => moduleId => state.modules.find((module) => module.module_id === moduleId)?state.modules.find((module) => module.module_id === moduleId)["module_name"]:"Empty",
	getModule: state => moduleId => state.modules.find((module) => module.module_id === moduleId)
};
