/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
	setCountrySearchQuery ({ commit }, query) {
		commit("SET_COUNTRY_SEARCH_QUERY", query);
	},
	fetchCountries ({ commit }) {
		return new Promise((resolve, reject) => {
			axios.get("/countries/all")
				.then((response) => {
					commit("SET_COUNTRIES", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addCountry ({ commit }, country) {
		return new Promise((resolve, reject) => {
			axios.post("/countries", country)
				.then((response) => {
					commit("ADD_COUNTRY", Object.assign(country, response.data));
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateCountry ({ commit }, country) {
		return new Promise((resolve, reject) => {
			axios.put("/countries", country)
				.then((response) => {
					commit("UPDATE_COUNTRY", Object.assign(country, response.data));
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteCountry ({ commit }, country_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/countries?countryId="+country_id)
				.then((response) => {
					commit("DELETE_COUNTRY", country_id);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	}
};
