/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js";

export default {
	SET_ID_TOKEN (state, idToken) {
		axios.defaults.headers.common["Authorization"] = `${idToken}`;
	},
	UPDATE_USER_INFO (state, user_info) {
		state.user_info = user_info;
	},
	UPDATE_RECOVERY_EMAIL (state, email) {
		state.recovery_email = email;
	},
	UPDATE_CHANGE_PASSWORD (state, obj) {
		state.oldPassword = obj.oldPassword;
		state.newPassword = obj.newPassword;

	},

};
