/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_CATEGORY_SEARCH_QUERY (state, query) {
		state.categorySearchQuery = query;
	},
	UPDATE_CATEGORY_FILTER (state, filter) {
		state.categoryFilter = filter;
	},

	// API
	SET_CATEGORIES (state, categories) {
		state.categories = categories;
	},
	ADD_CATEGORY (state, category) {
		state.categories.unshift(category);
	},
	UPDATE_CATEGORY (state, category) {
		const categoryIndex = state.categories.findIndex((t) => t.category_id === category.category_id);
		Object.assign(state.categories[categoryIndex], category);
	},
	DELETE_CATEGORY (state, category_id) {
		const categoryIndex = state.categories.findIndex((t) => t.category_id === category_id);
		state.categories.splice(categoryIndex,1);
	}
};
