/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_EVENT_SEARCH_QUERY (state, query) {
		state.eventSearchQuery = query;
	},
	UPDATE_EVENT_FILTER (state, filter) {
		state.eventFilter = filter;
	},

	// API
	SET_EVENTS (state, events) {
		state.events = events;
	},
	ADD_EVENT (state, event) {
		state.events.unshift(event);
	},
	UPDATE_EVENT (state, event) {
		const eventIndex = state.events.findIndex((t) => t.event_id === event.event_id);
		Object.assign(state.events[eventIndex], event);
	},
	DELETE_EVENT (state, event_id) {
		const eventIndex = state.events.findIndex((t) => t.event_id === event_id);
		state.events.splice(eventIndex,1);
	},
	UPLOAD_EVENT_IMAGE (state, obj) {
		const eventIndex = state.events.findIndex((t) => {
			const tmp = parseInt(obj.event_id);
			return t.event_id === tmp;
		});
		state.events[eventIndex]["image_url"]=obj.image_url;
	},
	REPLACE_UPLOAD_EVENT_IMAGE(){}
};
