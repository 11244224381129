/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
	isUserLoggedIn: () => {
		return localStorage.getItem("user_id")?true:false;
	},
	getIdToken: () => {
		return localStorage.getItem("idToken");
	},
	user_info:{},
	recovery_email:""
};
