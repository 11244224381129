/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedChatrooms: state => state.chatrooms.filter((chatroom) => {
		return true;
	}),
	// getChatroom: state => chatroomId => state.chatrooms.find((chatroom) => chatroom.id === chatroomId)

	getChatroom: state => chatroomId => state.chatrooms.find((chatroom) => {
		let tmp = parseInt(chatroomId);
		return chatroom.room_id === tmp;
	}),
};
