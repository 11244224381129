/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_CHATROOM_SEARCH_QUERY (state, query) {
		state.chatroomSearchQuery = query;
	},
	UPDATE_CHATROOM_FILTER (state, filter) {
		state.chatroomFilter = filter;
	},

	// API
	SET_CHATROOMS (state, chatrooms) {
		state.chatrooms = chatrooms;
	},
	ADD_CHATROOM (state, chatroom) {
		state.chatrooms.unshift(chatroom);
	},
	UPDATE_CHATROOM (state, chatroom) {
		const chatroomIndex = state.chatrooms.findIndex((t) => t.chatroom_id === chatroom.chatroom_id);
		Object.assign(state.chatrooms[chatroomIndex], chatroom);
	},
	DELETE_CHATROOM (state, chatroom_id) {
		const chatroomIndex = state.chatrooms.findIndex((t) => t.chatroom_id === chatroom_id);
		state.chatrooms.splice(chatroomIndex,1);
	},
	UPLOAD_CHATROOM_IMAGE (state, obj) {
		const tagIndex = state.tags.findIndex((t) => {
			const tmp = parseInt(obj.room_id);
			return t.room_id === tmp;
		});
		state.tags[tagIndex]["image_url"]=obj.image_url;
	},
	REMOVE_CHATROOM_IMAGE (state, obj) {
	},
	REPLACE_UPLOAD_CHATROOM_IMAGE(){}
};
