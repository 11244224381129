/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleEmployee from "./employee/moduleEmployee.js";
import moduleCountry from "./country/moduleCountry.js";
import moduleMedia from "./media/moduleMedia.js";
import moduleCategory from "./category/moduleCategory.js";
import moduleTag from "./tag/moduleTag.js";
import moduleModule from "./module/moduleModule.js";
import moduleBenefit from "./benefit/moduleBenefit.js";
import moduleEvent from "./event/moduleEvent.js";
import moduleChatroom from "./chatroom/moduleChatroom.js";
import moduleRoommember from "./roommember/moduleRoommember.js";
import moduleChangepassword from "./changepassword/moduleChangepassword.js";
import moduleOffer from "./offer/moduleOffer.js";
import moduleAuth from "./auth/moduleAuth.js";
import moduleDashboard from "./dashboard/moduleDashboard.js";


export default new Vuex.Store({
	getters,
	mutations,
	state,
	actions,
	modules: {
		employee: moduleEmployee,
		country: moduleCountry,
		media: moduleMedia,
		category: moduleCategory,
		tag: moduleTag,
		module: moduleModule,
		benefit: moduleBenefit,
		event: moduleEvent,
		chatroom: moduleChatroom,
		changepassword:moduleChangepassword,
		offer: moduleOffer,
		auth: moduleAuth,
		dashboard: moduleDashboard,
	},
	strict: process.env.NODE_ENV !== "production"
});
