/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedTags: state => state.tags.filter((tag) => {
		return true;
	}),
	getActiveTags: state => state.tags.filter((tag) => {
		return tag.status==1;
	}),
	getSelectedTags: state => tag_ids => state.tags.filter((tag) => {
		var splitArray = tag_ids.split(",");
		for(let i=0;i<splitArray.length;i++){
			const gettagId = parseInt(splitArray[i]);
			if(gettagId == tag.tag_id){
				return true;
			}
		}
	}), 
	getTag: state => tag_id => state.tags.find((tag) => {
		let tmp = parseInt(tag_id);
		return tag.tag_id === tmp;
	}),
};
