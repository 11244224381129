/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_EMPLOYEE_SEARCH_QUERY (state, query) {
		state.employeeSearchQuery = query;
	},
	UPDATE_EMPLOYEE_FILTER (state, filter) {
		state.employeeFilter = filter;
	},

	// API
	SET_EMPLOYEES (state, employees) {
		state.employees = employees;
	},
	ADD_EMPLOYEE (state, employee) {
		state.employees.unshift(employee);
	},
	UPDATE_EMPLOYEE (state, employee) {
		const employeeIndex = state.employees.findIndex((t) => t.employee_id === employee.employee_id);
		Object.assign(state.employees[employeeIndex], employee);
	},
	DELETE_EMPLOYEE (state, employee_id) {
		const employeeIndex = state.employees.findIndex((t) => t.employee_id === employee_id);
		state.employees.splice(employeeIndex,1);
	},
	UPLOAD_EMPLOYEE_IMAGE (state, obj) {
		const employeeIndex = state.employees.findIndex((t) => {
			const tmp = parseInt(obj.employee_id);
			return t.employee_id === tmp;
		});
		state.employees[employeeIndex]["image_url"]=obj.image_url;
	},
	REMOVE_EMPLOYEE_IMAGE (state, obj) {    
	},
	IMPORT_EMPLOYEE(){}
};
