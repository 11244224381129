/*=========================================================================================
  File Name: moduleDashboard.js
  Description: Country Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleDashboardState.js";
import mutations from "./moduleDashboardMutations.js";
import actions from "./moduleDashboardActions.js";
import getters from "./moduleDashboardGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
