/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_COUNTRY_SEARCH_QUERY (state, query) {
		state.countrySearchQuery = query;
	},
	UPDATE_COUNTRY_FILTER (state, filter) {
		state.countryFilter = filter;
	},

	// API
	SET_COUNTRIES (state, countries) {
		state.countries = countries;
	},
	ADD_COUNTRY (state, country) {
		state.countries.unshift(country);
	},
	UPDATE_COUNTRY (state, country) {
		const countryIndex = state.countries.findIndex((t) => t.country_id === country.country_id);
		Object.assign(state.countries[countryIndex], country);
	},
	DELETE_COUNTRY (state, country_id) {
		const countryIndex = state.countries.findIndex((t) => t.country_id === country_id);
		state.countries.splice(countryIndex,1);
	}
};
