/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/login'
        },
        {
          path: '/original-dashboard',
          name: 'Old Dashboard',
          component: () => import('./views/orgdashboard.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/dashboard',  //<-- not used instead use insights
          name: 'Dashboard',
          // component: () => import('./views/orgdashboard.vue'),
          component: () => import('./views/dash.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/insights',
          name: 'Insights',
          component: () => import('./views/insights.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/documentation',
          name: 'Documentation',
          component: () => import('./views/documentation.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/engagement',
          name: 'Engagement',
          component: () => import('./views/engagement.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/dash2',
          name: 'Dash',
          component: () => import('./views/orgdashboard.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/dashboard_demo',
          name: 'Dashboard',
          component: () => import('./views/graph.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/dashboard1',
          name: 'Dashboard',
          component: () => import('./views/chartjs/Chartjs.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            requiresAuth: true,
          }
        },       
        // =============================================================================
        // Application Routes
        // =============================================================================
        {
          path: '/story/all',
          name: 'Story',
          component: () => import('./views/apps/media/MediaList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Story', active: true }
            ],
            rule: 'editor',
            parent: 'story',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/story/new',
          name: 'Story',
          component: () => import('./views/apps/media/MediaEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Story',url: '/story/all'},
              { title: 'Add Story', active: true }
            ],
            rule: 'editor',
            parent: 'story',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/story/edit/:id',
          name: 'Story',
          component: () => import('./views/apps/media/MediaEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Story',url: '/story/all'},
              { title: 'Edit Story', active: true }
            ],
            rule: 'editor',
            parent: 'story',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/benefit/all',
          name: 'Benefits',
          component: () => import('./views/apps/benefit/Benefit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Benefits', active: true }
            ],
            rule: 'editor',
            parent: 'benefit',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/chatroom/all',
          name: 'Tables',
          component: () => import('./views/apps/chatroom/ChatroomList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Tables', active: true }
            ],
            rule: 'editor',
            parent: 'chatroom',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/chatroom/new',
          name: 'Tables',
          component: () => import('./views/apps/chatroom/ChatroomEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Tables', url: '/chatroom/all' },
              { title: 'Add Tables', active: true }
            ],
            rule: 'editor',
            parent: 'chatroom',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/chatroom/edit/:id',
          name: 'Chatroom',
          component: () => import('./views/apps/chatroom/ChatroomEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Chatroom', url: '/chatroom/all' },
              { title: 'Edit Chatroom', active: true }
            ],
            rule: 'editor',
            parent: 'chatroom',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/employee/all',
          name: 'Employees',
          component: () => import('./views/apps/employee/EmployeeList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Employees', active: true }
            ],
            rule: 'editor',
            parent: 'employee',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/employee/new',
          name: 'Employee',
          component: () => import('./views/apps/employee/EmployeeEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Employee',url: '/employee/all'},
              { title: 'Add Employee', active: true }
            ],
            rule: 'editor',
            parent: 'employee',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/employee/my_profile/:id',
          name: 'My Profile',
          component: () => import('./views/apps/employee/EmployeeView.vue'),
          meta: {
            rule: 'editor',
            parent: 'employee',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/employee/changepassword',
          name: 'My Profile',
          component: () => import('./views/apps/employee/EmployeeChangepassword.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Change Password', active: true }
            ],
            rule: 'editor',
            parent: 'employee',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/employee/edit/my_profile/:id',
          name: 'Profile',
          component: () => import('./views/apps/employee/EmployeeEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Profile', url: '/employee/my_profile' },
              { title: 'Edit Profile', active: true }
            ],
            rule: 'editor',
            parent: 'employee',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/employee/:id',
          name: 'Profile',
          component: () => import('./views/apps/employee/EmployeeView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'My Profile', active: true }
            ],
            rule: 'editor',
            parent: 'employee',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/employee/edit/:id',
          name: 'Employee',
          component: () => import('./views/apps/employee/EmployeeEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Employee',url: '/employee/all'},
              { title: 'Edit Employee', active: true }
            ],
            rule: 'editor',
            parent: 'employee',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/event/all',
          name: 'Events',
          component: () => import('./views/apps/event/EventList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Events', active: true }
            ],
            rule: 'editor',
            parent: 'event',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/offers/all',
          name: 'Offers',
          component: () => import('./views/apps/offer/Offer.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Offers', active: true }
            ],
            rule: 'editor',
            parent: 'offer',
            no_scroll: true,
            requiresAuth: true,
          }
        },
        {
          path: '/event/edit/:id',
          name: 'Event',
          component: () => import('./views/apps/event/EventEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Event', url: '/event/all'  },
              { title: 'Edit Event', active: true }
            ],
            rule: 'editor',
            parent: 'event',
            no_scroll: true,
            requiresAuth: true,
          }
        },  
        {
          path: '/event/new',
          name: 'Event',
          component: () => import('./views/apps/event/EventEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Event', url: '/event/all'  },
              { title: 'Add Event', active: true }
            ],
            rule: 'editor',
            parent: 'event',
            no_scroll: true,
            requiresAuth: true,
          }
        }, 
        // =============================================================================
        // routes ends here
        

        // =============================================================================
        // Pages Routes
        // =============================================================================
    
        {
          path: '/pages/user-settings',
          name: 'page-user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'User Settings', active: true }
            ],
            pageTitle: 'Settings',
            rule: 'editor'
          }
        },
     
        {
          path: '/pages/knowledge-base',
          name: 'page-knowledge-base',
          component: () => import('@/views/pages/KnowledgeBase.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'KnowledgeBase', active: true }
            ],
            pageTitle: 'KnowledgeBase',
            rule: 'editor'
          }
        },
        {
          path: '/pages/knowledge-base/category',
          name: 'page-knowledge-base-category',
          component: () => import('@/views/pages/KnowledgeBaseCategory.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'KnowledgeBase', url: '/pages/knowledge-base' },
              { title: 'Category', active: true }
            ],
            parent: 'page-knowledge-base',
            rule: 'editor'
          }
        },
        {
          path: '/pages/knowledge-base/category/question',
          name: 'page-knowledge-base-category-question',
          component: () => import('@/views/pages/KnowledgeBaseCategoryQuestion.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'KnowledgeBase', url: '/pages/knowledge-base' },
              { title: 'Category', url: '/pages/knowledge-base/category' },
              { title: 'Question', active: true }
            ],
            parent: 'page-knowledge-base',
            rule: 'editor'
          }
        },
        {
          path: '/pages/search',
          name: 'page-search',
          component: () => import('@/views/pages/Search.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Search', active: true }
            ],
            pageTitle: 'Search',
            rule: 'editor'
          }
        },
        {
          path: '/pages/invoice',
          name: 'page-invoice',
          component: () => import('@/views/pages/Invoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Invoice', active: true }
            ],
            pageTitle: 'Invoice',
            rule: 'editor'
          }
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/show/demo',
          name: 'see-demo',
          component: () => import('@/views/Demo.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.path === '/login' ||
    to.path === '/pages/forgot-password' ||
    to.path === '/pages/error-404' ||
    to.path === '/pages/error-500' ||
    to.path === '/pages/register' ||
    to.path === '/callback' ||
    localStorage.getItem('idToken') || 1
  ) {
    return next()
  }
})


export default router
