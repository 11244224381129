/*=========================================================================================
  File Name: moduleEvent.js
  Description: Event Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from "./moduleEventState.js";
import mutations from "./moduleEventMutations.js";
import actions from "./moduleEventActions.js";
import getters from "./moduleEventGetters.js";

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
