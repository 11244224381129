/*=========================================================================================
  File Name: moduleCategoryActions.js
  Description: Category Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
	setCategorySearchQuery ({ commit }, query) {
		commit("SET_CATEGORY_SEARCH_QUERY", query);
	},
	fetchCategories ({ commit }) {
		return new Promise((resolve, reject) => {
			axios.get("/categories/all")
				.then((response) => {
					commit("SET_CATEGORIES", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	addCategory ({ commit }, category) {
		return new Promise((resolve, reject) => {
			axios.post("/categories", category)
				.then((response) => {
					commit("ADD_CATEGORY", Object.assign(category, response.data));
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	updateCategory ({ commit }, category) {
		return new Promise((resolve, reject) => {
			axios.put("/categories", category)
				.then((response) => {
					commit("UPDATE_CATEGORY", response.data);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	},

	deleteCategory ({ commit }, category_id) {
		return new Promise((resolve, reject) => {
			axios.delete("/categories?categoryId="+category_id)
				.then((response) => {
					commit("DELETE_CATEGORY", category_id);
					resolve(response);
				})
				.catch((error) => { reject(error); });
		});
	}
};
