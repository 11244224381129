/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	SET_BENEFIT_SEARCH_QUERY (state, query) {
		state.benefitSearchQuery = query;
	},
	UPDATE_BENEFIT_FILTER (state, filter) {
		state.benefitFilter = filter;
	},

	// API
	SET_BENEFITS (state, benefits) {
		state.benefits = benefits;
	},
	ADD_BENEFIT (state, benefit) {
		state.benefits.unshift(benefit);
	},
	UPDATE_BENEFIT (state, benefit) {
		const benefitIndex = state.benefits.findIndex((t) => t.benefit_id === benefit.benefit_id);
		Object.assign(state.benefits[benefitIndex], benefit);
	},
	DELETE_BENEFIT (state, benefit_id) {
		const benefitIndex = state.benefits.findIndex((t) => t.benefit_id === benefit_id);
		state.benefits.splice(benefitIndex,1);
	},
	UPLOAD_BENEFIT_DOC (state, obj) {
		// const benefitIndex = state.benefits.findIndex((t) => {
		//   const tmp = parseInt(obj.benefit_id)
		//   return t.benefit_id === tmp
		// })
		// state.benefits[benefitIndex]['document_url']=obj.document_url
	},
	REMOVE_BENEFIT_IMAGE (state, obj) {
	},
	REMOVE_BENEFIT_DOC (state, obj) {
	},
	UPLOAD_BENEFIT_IMAGE (state, obj) {
		const benefitIndex = state.benefits.findIndex((t) => {
			const tmp = parseInt(obj.benefit_id);
			return t.benefit_id === tmp;
		});
		state.benefits[benefitIndex]["image_url"]=obj.image_url;
	}
};
