/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	queriedMedias: state => state.medias.filter((media) => {
		return true;
	}),
	getMedia: state => mediaId => state.medias.find((media) => {
		const temp = parseInt(mediaId);
		return media.media_id === temp;
	})
	// getMedia: state => mediaId => state.medias.find((media) => media.id === mediaId)
};
